<template>
  <div>
    <div class="header">
      <div class="header_top">
        <img src="../../assets/left.png" alt @click="goBack" />
        <span class="center">财务功能</span>
        <img src="../../assets/right.png" alt />
        <span class="center">企业累计流水统计</span>
        <img src="../../assets/right.png" alt />
        <span class="center">统计详情</span>
      </div>
    </div>
    <el-card>
      <el-card class="box-card">
        <div class="left_top">
          <div class="left_icon">
            <span></span>
            <span>基本信息</span>
          </div>
        </div>

        <div>
          <el-row class="el-row-padding">
            <el-col :span="12" class="el-col-lineheight">企业名称:{{ statisticsInfo.enterpriseName }}</el-col>
            <!-- <el-col :span="12" class="el-col-lineheight">
            统一社会信用代码：{{ statisticsInfo.from_id }}
            </el-col>-->
            <el-col :span="12" class="el-col-lineheight">企业编号：{{ statisticsInfo.enterprise }}</el-col>
            <el-col :span="12" class="el-col-lineheight">累计金额：{{ statisticsInfo.total | money }}元</el-col>
            <el-col
              :span="12"
              class="el-col-lineheight"
            >结算平台：{{ statisticsInfo.pay_platform | platformVal }}</el-col>
            <el-col :span="12" class="el-col-lineheight">
              结算时间：{{
              this.$store.state.statisticsEnterprise.dateTime[0] + " 00:00:00"
              }}
              ~
              {{
              this.$store.state.statisticsEnterprise.dateTime[1] + " 23:59:59"
              }}
            </el-col>
          </el-row>
        </div>
      </el-card>
      <el-card class="box-card">
        <div class="left_top" style="margin:0 0 20px 0">
          <div class="left_icon">
            <span></span>
            <span>工资包列表</span>
          </div>
        </div>

        <el-table :data="statisticsPackageList">
          <el-table-column label="工资包名称" prop="name" :show-overflow-tooltip="true" width="200">
            <template slot-scope="scope">
              <p>{{ scope.row.name }}</p>
              <div v-prev path="/system/task/detail" :query="{ code: scope.row.code }">
                <span class="spanClick">{{ scope.row.code }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="结算企业" prop="enterpriseName" width="250"></el-table-column>
          <el-table-column
            label="合同名称"
            prop="contractName"
            width="350"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column label="创客人数" prop="makerNumber"></el-table-column>
          <el-table-column label="结算中心">
            <template slot-scope="scope">{{ scope.row.pay_platform | platformVal }}</template>
          </el-table-column>
          <el-table-column label="结算金额">
            <template slot-scope="scope">{{ scope.row.total | money }}元</template>
          </el-table-column>
          <el-table-column label="服务费" prop="serviceFee">
            <template slot-scope="scope">{{ scope.row.serviceFee | money }}元</template>
          </el-table-column>
          <el-table-column label="费率" prop="rate">
            <template slot-scope="scope">{{ scope.row.rate }}%</template>
          </el-table-column>
          <el-table-column label="完成时间" prop="completeTime"></el-table-column>
        </el-table>
      </el-card>
    </el-card>
    <div class="pagination">
      <span class="clickColor">共{{ packageTotal }}条</span>
      <span class="clickColor" style="margin: 0 10px">每页{{ packagePageSize }}条</span>
      <el-pagination
        v-if="
          statisticsPackageList !== undefined &&
            statisticsPackageList.length > 0
        "
        background
        layout="prev,pager, next,jumper"
        :current-page.sync="packagePageNumber"
        :page-size="packagePageSize"
        @current-change="packagePageChange"
        :total="packageTotal"
        @size-change="handleSizeChange"
        prev-text="上一页"
        next-text="下一页"
      ></el-pagination>
    </div>
    <!-- <el-pagination
      class="page-nation"
      style="margin-top: 10px"
      v-if="
        statisticsPackageList !== undefined && statisticsPackageList.length > 0
      "
      background
      layout="prev, pager, next"
      :page-size="packagePageSize"
      :current-page="packagePageNumber"
      @current-change="packagePageChange"
      :total="packageTotal"
    >
    </el-pagination>-->
  </div>
</template>

<script>
import { api } from '/src/api/base/index';
export const taskList = api()('task.list.json');
export default {
  components: {
    // Divider: () => import("/src/components/divider")
  },
  data () {
    return {
      packageList: [],
      packagePageNumber: 1,

      packagePageSize: 10,
      packageTotal: 0,
      statisticsInfo: {},
      statisticsPackageList: []
    };
  },
  created () {
    this.statisticsInfo =
      this.$store.state.statisticsEnterprise.enterprise || {};
    if (JSON.stringify(this.statisticsInfo) !== '{}') {
      this.packageList = this.statisticsInfo.tasks.split(',');
    } else {
      this.goBack();
    }
    this.getPackageList();
  },
  methods: {
    async getPackageList () {
      const result = await taskList({
        pageNumber: this.packagePageNumber,
        pageSize: this.packagePageSize,
        in_code: this.packageList.join(','),
        enterpriseAccount: this.statisticsInfo.enterprise
      });
      this.statisticsPackageList = result.list || [];
      this.packageTotal = result.total || 0;
    },
    handleSizeChange (val) {
      this.packagePageNumber = val;
      this.getPackageList();
    },
    packagePageChange (val) {
      this.packagePageNumber = val;
      this.getPackageList();
    },
    goBack () {
      this.$router.go(-1);
    }
  }
};
</script>

<style scoped lang="scss">
.el-col-lineheight {
  line-height: 36px;
  font-size: 14px;
  text-align: left;
}

.spanClick {
  color: #409eff;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}
</style>
